import {
  FETCH_TRANSACTIONS,
  SET_TRANSACTIONS,
  SET_PENDING_TRANSACTIONS,
  ADD_PENDING_TRANSACTION,
  SET_TRANSACTIONS_LOADING,
  SEND_TRANSACTION,
  PROCESS_PENDING_TRANSACTION,
} from '../action-types/transaction.types';
// eslint-disable-next-line import/no-cycle
import Transaction from '../../models/transaction';

export const setTransactions = (payload) => (dispatch) => {
  dispatch({ type: SET_TRANSACTIONS, payload });
};

export const addPendingTransaction = (payload) => (dispatch) => {
  dispatch({ type: ADD_PENDING_TRANSACTION, payload });
};

export const setPendingTransactions = (payload) => (dispatch) => {
  dispatch({ type: SET_PENDING_TRANSACTIONS, payload });
};

export const transactionLoaded = () => (dispatch) => {
  dispatch({
    type: SET_TRANSACTIONS_LOADING,
    payload: false,
  });
};

export const queueTransaction = (payload) => (dispatch) => {
  dispatch({
    type: SEND_TRANSACTION,
    payload,
  });
};

export const processPending = (payload) => (dispatch) => {
  dispatch({
    type: PROCESS_PENDING_TRANSACTION,
    payload,
  });
};

export const allTransactions = (payload) => (dispatch, getState) => {
  const { account } = getState();
  if (account && account.address) {
    dispatch({
      type: FETCH_TRANSACTIONS,
      payload,
    });
  }
};

export const checkPendingStatus = () => (dispatch, getState) => {
  const { transactions } = getState();
  const promises = [];
  if (transactions?.pending.length) {
    const counter = transactions.pending.length;
    const list = transactions.pending.map((transaction) => {
      const promise = Transaction.find(transaction.txhash);
      promises.push(promise);
      return transaction;
    });
    Promise.all(promises).then((values) => {
      values.map((value, index) => {
        if (value.error || value.height > 0) {
          list.splice(index, 1);
        }
        return value;
      });
      if (counter !== list.length) {
        dispatch(setPendingTransactions(list));
        dispatch(allTransactions());
      }
    });
  }
};
