import moment from 'moment';
// import firebase from '../lib/firebase';
// import BaseModel from './_model';
import Cosmos from '../lib/cosmos';

const DENOM = process.env.REACT_APP_COSMOS_DENOM;
export default class Transaction {
  static async sent(address, page = 1) {
    return this.get(
      {
        'message.sender': address,
      },
      page,
    );
  }

  static async get(params, page = 1, limit = 100) {
    const response = await fetch(
      `${
        process.env.REACT_APP_COSMOS_NODE_URL
      }/txs?${new URLSearchParams({
        ...params,
        page,
        limit,
      })}`,
    );
    return response.json();
  }

  static async received(address, page = 1) {
    return this.get(
      {
        'transfer.recipient': address,
      },
      page,
    );
  }

  static async find(id) {
    const response = await fetch(
      `${process.env.REACT_APP_COSMOS_NODE_URL}/txs/${id}`,
    );
    const result = await response.json();
    if (!result.error) {
      return result;
    }
    throw result;
  }

  static async create(
    account,
    toAddress,
    amount,
    ecpairPriv,
    details = {},
  ) {
    return this.process(
      account,
      [this.generateMessage(account.address, toAddress, amount)],
      ecpairPriv,
      details,
    );
  }

  static async createBatch(
    account,
    addresses,
    amount,
    ecpairPriv,
    details = {},
  ) {
    const msgs = addresses.map((value) =>
      this.generateMessage(account.address, value, amount),
    );
    return this.process(account, msgs, ecpairPriv, details);
  }

  static async process(account, msgs, ecpairPriv, details = {}) {
    const stdSignMsg = Cosmos.newStdMsg({
      msgs,
      chain_id: process.env.REACT_APP_COSMOS_CHAIN_ID,
      fee: {
        amount: [
          {
            amount: String(2 * msgs.length),
            denom: 'evtx',
          },
        ],
        gas: String(200000 * msgs.length),
      },
      memo: this.memoCleanup(details),
      account_number: String(account.account_number),
      sequence: String(account.sequence),
    });
    const signedTx = Cosmos.sign(stdSignMsg, Buffer.from(ecpairPriv));
    const response = await Cosmos.broadcast(signedTx);
    if (!response.code) {
      return response;
    }
    throw response;
  }

  static memoCleanup(details) {
    return JSON.stringify(
      details,
      (key, value) => {
        // Filtering out properties
        if (typeof value === 'string') {
          return value.replace('&', 'and');
        }
        return value;
      },
      '',
    );
  }

  static generateMessage(address, toAddress, amount) {
    return {
      type: 'cosmos-sdk/MsgSend',
      value: {
        amount: [
          {
            amount: amount > 0 ? String(amount) : String(1),
            denom: amount > 0 ? DENOM : 'evtx',
          },
        ],
        from_address: address,
        to_address: toAddress,
      },
    };
  }

  get date() {
    return moment(
      this.parseFirestoreDate(this.data.created_at).toDate(),
    ).format('MMM Do H:mm');
  }

  static getAmount(transactions, address) {
    let amount = 0;
    if (transactions.length) {
      transactions.map((tx) => {
        if (
          tx.value.to_address === address ||
          tx.value.from_address === address
        ) {
          amount += this.getCoin(tx.value.amount);
        }
        return tx;
      });
    }
    return amount;
  }

  static getCoin(coins) {
    if (coins.length) {
      const coin = coins.filter((cn) => cn.denom === DENOM);
      return coin.length ? Number(coin[0].amount) : 0;
    }
    return 0;
  }
}
