import {
  all,
  call,
  put,
  takeLatest,
  delay,
  takeLeading,
  takeEvery,
  select,
  take,
  actionChannel,
} from 'redux-saga/effects';
import { v4 as uuid } from 'uuid';
import {
  SET_TRANSACTIONS,
  SET_PENDING_TRANSACTIONS,
  SEND_TRANSACTION,
  FETCH_TRANSACTIONS,
  ADD_PENDING_TRANSACTION,
  SET_TRANSACTIONS_LOADING,
  MAKE_TRANSACTIONS,
  PROCESS_PENDING_TRANSACTION,
} from '../action-types/transaction.types';
import { fetchAccount } from './account.sagas';
import { FETCH_SCHEDULES } from '../action-types/schedule.types';
import Transaction from '../../models/transaction';

export const getAccount = (state) => state.account;
export const getPendingTransactions = (state) =>
  state.transactions.pending;

function* getTransactions(func, address) {
  let page = 1;
  let total = 1;
  const collection = [];
  do {
    const result = yield call([Transaction, func], address, page);
    total = Number(result?.page_total);
    collection.push(...result.txs);
    page += 1;
  } while (page <= total);
  return collection;
}

function* fetchAll() {
  try {
    const account = yield select(getAccount);

    const sent = yield call(
      getTransactions,
      Transaction.sent,
      account.address,
    );
    const received = yield call(
      getTransactions,
      Transaction.received,
      account.address,
    );
    const transactions = [...sent, ...received];
    const result = transactions.sort(
      (a, b) => new Date(b.timestamp) - new Date(a.timestamp),
    );
    yield put({
      type: SET_TRANSACTIONS,
      payload: result,
    });

    yield put({
      type: SET_TRANSACTIONS_LOADING,
      payload: false,
    });
  } catch (error) {
    console.log(error);
  }
}

function* addPending({ payload }) {
  const item = {
    queue_id: uuid(),
    ...payload,
  };
  yield put({
    type: ADD_PENDING_TRANSACTION,
    payload: item,
  });

  yield put({
    type: MAKE_TRANSACTIONS,
    payload: item,
  });
}

function* removePending(payload) {
  const transactions = yield select(getPendingTransactions);
  const filtered = transactions.filter(
    (transaction) => transaction.queue_id !== payload.queue_id,
  );
  yield put({
    type: SET_PENDING_TRANSACTIONS,
    payload: filtered,
  });
}

function* processPending() {
  const transactions = yield select(getPendingTransactions);
  yield all(
    transactions.map((transaction) =>
      put({
        type: MAKE_TRANSACTIONS,
        payload: transaction,
      }),
    ),
  );
}

function* checkTransactions(tx) {
  let transaction = tx;
  let inc = 2;
  while (transaction.height === '0') {
    yield delay(1000 * inc);
    try {
      inc *= 2;
      transaction = yield call(
        [Transaction, Transaction.find],
        transaction.txhash,
      );
    } catch (e) {
      //
      transaction = inc > 90 ? {} : transaction;
    }
  }
}

function* makeTransaction({ drops, address, details }) {
  const account = yield select(getAccount);
  try {
    const { ecpairPriv } = account;
    const transaction = yield call(
      [Transaction, Transaction.create],
      account,
      address,
      drops,
      ecpairPriv,
      details,
    );
    yield call(checkTransactions, transaction);
    yield call(fetchAccount, {
      payload: { address: account.address },
    });
    yield put({
      type: FETCH_SCHEDULES,
      payload: {
        address: account.address,
      },
    });
  } catch (error) {
    console.log(error);
    yield call(fetchAccount, {
      payload: { address: account.address },
    });
  }
}

export default function* watchTransactions() {
  yield takeLatest(FETCH_TRANSACTIONS, fetchAll);
  yield takeEvery(SEND_TRANSACTION, addPending);
  yield takeLeading(PROCESS_PENDING_TRANSACTION, processPending);

  const requestChan = yield actionChannel(MAKE_TRANSACTIONS);
  while (true) {
    const { payload } = yield take(requestChan);

    yield call(makeTransaction, payload);
    yield call(removePending, payload);
  }
}
