import React from 'react';
import PropTypes from 'prop-types';
import BackIcon from '../../assets/icons/back';

const BackButton = ({ onClick }) => (
  <button
    type="button"
    className="p-0 b-0 bg-translate focus:outline-none outline-none p-2 border-solid border-2 border-translucent rounded-md"
    onClick={onClick}
  >
    <BackIcon color="color" />
  </button>
);

BackButton.propTypes = {
  onClick: PropTypes.func,
};

BackButton.defaultProps = {
  onClick: () => {},
};

export default BackButton;
