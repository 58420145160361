import React from 'react';

const Safaris = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    className="inline-block"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.5766 9.49919L5.36546 16.0885C5.15496 16.5205 5.61555 16.9717 6.04312 16.7524L12.5483 13.416C12.9208 13.2249 13.224 12.9217 13.4151 12.5491L16.7516 6.04397C16.9709 5.61641 16.5196 5.15582 16.0876 5.36632L9.49832 8.57746C9.09677 8.77315 8.77229 9.09763 8.5766 9.49919Z"
      fill="black"
    />
    <path
      d="M12.2421 9.74791C12.9255 10.4313 12.9255 11.5394 12.2421 12.2228C11.5587 12.9062 10.4506 12.9062 9.76722 12.2228C9.0838 11.5394 9.0838 10.4313 9.76722 9.74791C10.4506 9.0645 11.5587 9.0645 12.2421 9.74791Z"
      fill="#8870FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 20.5C16.2467 20.5 20.5 16.2467 20.5 11C20.5 5.75329 16.2467 1.5 11 1.5C5.75329 1.5 1.5 5.75329 1.5 11C1.5 16.2467 5.75329 20.5 11 20.5ZM11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22Z"
      fill="black"
    />
  </svg>
);

export default Safaris;
