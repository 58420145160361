import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { importMDX } from 'mdx.macro';
import Navbar from './components/Navbar';
import ContentWrapper from './components/ContentWrapper';
import TransactionProvider from './providers/Transaction';
import { useViewport } from './helpers/Viewport';
import { getSchedules } from './redux/actions/schedule.actions';
import { checkAuth } from './redux/actions/auth.actions';
import { getOrganizer } from './redux/actions/organizer.actions';
import { getActiveTopup } from './redux/actions/topup.actions';

const Home = React.lazy(() => import('./pages/Home'));
const Schedule = React.lazy(() => import('./pages/Schedule'));
const Join = React.lazy(() => import('./pages/Join'));
const SignUp = React.lazy(() => import('./pages/SignUp'));
const Transaction = React.lazy(() => import('./pages/Transaction'));
const TopUp = React.lazy(() => import('./pages/TopUp'));
const NotFound = React.lazy(() => import('./pages/404page'));
const PhoneVerification = React.lazy(() =>
  import('./pages/PhoneVerification'),
);
const DesktopTablet = React.lazy(() =>
  import('./pages/DesktopTabletView'),
);

const Terms = React.lazy(() => importMDX('./mdx/terms.md'));
const Imprint = React.lazy(() => importMDX('./mdx/impressum.md'));

const Routes = () => {
  const dispatch = useDispatch();
  const layout = useSelector((state) => state.layout);

  useEffect(() => {
    dispatch(checkAuth());
    dispatch(getSchedules());
    dispatch(getActiveTopup());
    dispatch(getOrganizer());
  });

  const { width, mobileBrowser } = useViewport();
  const breakpoint = 850;
  return (
    <>
      {width < breakpoint && mobileBrowser ? (
        <Router>
          {layout.navbarVisible && <Navbar balance={500} />}
          <TransactionProvider />
          <div className="p-6 w-full h-full m-0">
            <Switch>
              <Route exact path="/" render={() => <Home />} />
              <Route path="/schedule" render={() => <Schedule />} />
              <Route path="/join" render={() => <Join />} />
              <Route path="/sign-up" render={() => <SignUp />} />
              <Route
                path="/phone-verification"
                render={() => <PhoneVerification />}
              />
              <Route
                path="/transactions"
                render={() => <Transaction />}
              />
              <Route
                path="/terms"
                render={() => (
                  <ContentWrapper>
                    <Terms />
                  </ContentWrapper>
                )}
              />
              <Route
                path="/imprint"
                render={() => (
                  <ContentWrapper>
                    <Imprint />
                  </ContentWrapper>
                )}
              />
              <Route path="/top-up/*" render={() => <TopUp />} />
              <Route path="*" render={() => <NotFound />} />
            </Switch>
          </div>
        </Router>
      ) : (
        <Router>
          <Route exact path="*" render={() => <DesktopTablet />} />
        </Router>
      )}
    </>
  );
};

export default Routes;
