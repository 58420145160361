import {
  SET_ACCOUNT,
  SET_ACCOUNT_DETAILS,
  SET_PRIVATE_KEY,
} from '../action-types/account.types';
import { account as initialState } from '../initialState.json';

const accountReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_PRIVATE_KEY:
    case SET_ACCOUNT_DETAILS:
      return { ...state, ...payload };
    case SET_ACCOUNT:
      return payload;
    default:
      return state;
  }
};

export default accountReducer;
