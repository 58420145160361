import {
  SET_ACTIVE_TOPUP,
  FETCH_TOPUP,
} from '../action-types/topup.types';

export const getActiveTopup = () => (dispatch, getState) => {
  const { auth } = getState();
  if (auth.user) {
    dispatch({
      type: FETCH_TOPUP,
      payload: {
        uid: auth.user.uid,
      },
    });
  }
};

export const clearActiveTopup = () => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_TOPUP,
    payload: null,
  });
};
