import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import Button from '../ui/Button';

const InstallAndroid = ({ t }) => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  window.addEventListener('beforeinstallprompt', (e) => {
    // Prevent the mini-infobar from appearing on mobile
    e.preventDefault();
    // Stash the event so it can be triggered later.
    setDeferredPrompt(e);
    // Update UI notify the user they can install the PWA
  });

  const install = () => {
    // Show the install prompt
    deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        alert('User accepted the install prompt');
      } else {
        alert('User dismissed the install prompt');
      }
    });
  };
  return (
    <div className="bg-primary bottom-0 fixed w-full left-0 text-center p-6">
      <div className="text-3xl font-primary my-6">
        <span>
          {ReactHtmlParser(t('popup.install.banner.title'))}
        </span>
      </div>
      <div className="text-base pb-5">
        {ReactHtmlParser(t('popup.install.banner.subtitle'))}
      </div>

      <div className="mx-5 my-6">
        <Button type="primary" onClick={install}>
          {t('popup.install.banner.action.button')}
        </Button>
      </div>
    </div>
  );
};

InstallAndroid.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(InstallAndroid);
