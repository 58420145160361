/* eslint-disable no-underscore-dangle */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PageLoader from '../components/PageLoader';
import { setAuth } from '../redux/actions/auth.actions';
import { setRedirect as setRedirectFunction } from '../redux/actions/router.actions';

const withAuth = (Component) => {
  const el = class RenderComponent extends React.Component {
    _isMounted = false;

    componentDidMount() {
      this._isMounted = true;
      this.switchRoute();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      if (snapshot !== null && this._isMounted) {
        this.switchRoute();
      }
    }

    componentWillUnmount() {
      this._isMounted = false;
    }

    switchRoute() {
      const { auth, history, location, setRedirect } = this.props;
      if (!auth.user && auth.loaded) {
        setRedirect(location);
        history.push('/join');
      }
    }

    renderContent() {
      const { auth } = this.props;
      if (!auth.loaded) {
        return <PageLoader />;
      }
      if (auth.user) {
        return <Component {...this.props} />;
      }
      return null;
    }

    render() {
      return <>{this.renderContent()}</>;
    }
  };

  el.propTypes = {
    auth: PropTypes.instanceOf(Object).isRequired,
    history: PropTypes.instanceOf(Object).isRequired,
    location: PropTypes.instanceOf(Object).isRequired,
    setRedirect: PropTypes.func.isRequired,
  };

  const mapStateToProps = (state) => ({
    auth: state.auth,
  });

  return connect(mapStateToProps, {
    setAuth,
    setRedirect: setRedirectFunction,
  })(withRouter(el));
};
export default withAuth;
