import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setTheme } from '../redux/actions/theme.actions';

class CheckTheme extends Component {
  componentDidMount() {
    this.observe();
  }

  observe = () => {
    const { addTheme } = this.props;
    const elemToObserve = document.getElementById(
      'app-view-container',
    );
    if (elemToObserve.classList.length) {
      const theme = elemToObserve.classList[0].replace('theme-', '');
      return addTheme(theme);
    }
    return addTheme('default');
  };

  render() {
    return <></>;
  }
}

CheckTheme.propTypes = {
  addTheme: PropTypes.func.isRequired,
};

export default connect(null, { addTheme: setTheme })(CheckTheme);
