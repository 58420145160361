/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setNavbarVisibility as setNavbarVisibilityFunction } from '../redux/actions/layout.actions';

const withoutNavbar = (Component) => {
  const el = class RenderComponent extends React.Component {
    renderContent() {
      return <Component {...this.props} />;
    }

    render() {
      return (
        <div
          className="fixed w-full top-0 bg-primary h-screen overflow-y-scroll overflow-x-hidden w-full left-0 p-6"
          style={{
            zIndex: 999,
          }}
        >
          {this.renderContent()}
        </div>
      );
    }
  };

  el.propTypes = {
    setNavbarVisibility: PropTypes.func.isRequired,
    layout: PropTypes.instanceOf(Object).isRequired,
  };

  return connect(
    (state) => ({
      layout: state.layout,
    }),
    {
      setNavbarVisibility: setNavbarVisibilityFunction,
    },
  )(el);
};

export default withoutNavbar;
