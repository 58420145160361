import React from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';

const Dialog = ({ children }) => (
  <div className="fixed left-0 z-999 top-0 w-full h-screen bg-overlay flex items-center justify-center">
    <AnimatePresence>
      <motion.div
        initial={{ scale: 0.5 }}
        animate={{ scale: 0.9 }}
        exit={{ scale: 0 }}
        transition={{
          duration: 0.3,
          type: 'spring',
          delay: 0.1,
          stiffness: 600,
          damping: 100,
          mass: 1,
        }}
        className="w-full"
      >
        <div className="rounded-lg overflow-hidden relative w-4/5 p-0 mx-auto bg-secondary">
          {children}
        </div>
      </motion.div>
    </AnimatePresence>
  </div>
);

Dialog.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]).isRequired,
};

export default Dialog;
