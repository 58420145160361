import {
  SET_ACCOUNT,
  FETCH_ACCOUNT,
  SET_ACCOUNT_DETAILS,
  SET_PRIVATE_KEY,
} from '../action-types/account.types';

export const setAccount = (account) => (dispatch) => {
  return dispatch({
    type: SET_ACCOUNT,
    payload: account,
  });
};

export const getBalance = () => (dispatch, getState) => {
  const { account } = getState();
  if (account && account.address) {
    dispatch({
      type: FETCH_ACCOUNT,
      payload: { address: account.address },
    });
  }
};

export const setPrivateKey = (key) => (dispatch) => {
  return dispatch({
    type: SET_PRIVATE_KEY,
    payload: { ecpairPriv: key },
  });
};

export const setAddress = (address) => (dispatch) => {
  return dispatch({
    type: SET_ACCOUNT_DETAILS,
    payload: { address },
  });
};
