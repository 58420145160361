/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import log from 'loglevel';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';
import Alert from '../ui/Alert';
import { logout } from '../../redux/actions/auth.actions';
import RewardAll from '../RewardAll';

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLogout: false,
    };
  }

  componentDidMount() {
    this.setState({
      showLogout: process.env.NODE_ENV === 'development',
    });
  }

  sendMail = () => {
    const { REACT_APP_SUPPORT_EMAIL } = process.env;
    window.location.href = `mailto:${REACT_APP_SUPPORT_EMAIL}`;
  };

  items() {
    const { auth, t } = this.props;
    return [
      {
        show: true,
        link: '/schedule',
        label: t('menu.item.schedule'),
      },
      {
        show: true,
        link: '/',
        label: t('menu.item.how-it-works'),
      },
      {
        show: !auth.user,
        link: '/join',
        label: t('menu.item.join'),
      },
      {
        show: !!auth.user,
        link: '/transactions',
        label: t('menu.item.transactions'),
      },
    ];
  }

  logout(e) {
    e.stopPropagation();
    const { history, userLogout } = this.props;

    if (process.env.NODE_ENV === 'development') {
      userLogout()
        .then(() => {
          history.push('/');
        })
        .catch((error) => {
          log.error(error);
        });
    }
  }

  render() {
    const { auth, alert, t, open, setOpen } = this.props;
    const { showLogout } = this.state;
    return (
      <>
        {open ? (
          <AnimatePresence>
            <motion.div
              key="menu"
              initial={{ x: -150, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.25 }}
              exit={{ x: -150 }}
              className="w-full fixed h-screen text-primary bg-primary z-99 overflow-hidden overflow-y-scroll transform:origin-right"
            >
              <ul
                className={['w-full text-black px-8'].join(' ')}
                style={{
                  paddingTop: '45%',
                  paddingBottom: !alert.message ? '20%' : 0,
                }}
              >
                {this.items().map((item) => {
                  if (item.show) {
                    return (
                      <li
                        key={item.link}
                        onClick={() => setOpen()}
                        onKeyDown={() => setOpen()}
                        className="font-primary cursor-pointer text-black text-4xl my-3 list-none capitalize"
                      >
                        <Link className="text-black" to={item.link}>
                          {item.label}
                        </Link>
                      </li>
                    );
                  }
                  return null;
                })}

                {auth.user && showLogout && (
                  <li
                    onClick={(e) => this.logout(e)}
                    onKeyDown={(e) => this.logout(e)}
                    className="font-primary cursor-pointer text-4xl my-3 list-none"
                  >
                    {t('menu.item.logout')}
                  </li>
                )}
                <li className="m-0 p-0">
                  <Alert />
                </li>
              </ul>

              <ul className="w-full px-8 pb-32">
                <li
                  onClick={() => setOpen()}
                  onKeyDown={() => setOpen()}
                  className="cursor-pointer text-lg list-none"
                >
                  <Link className="text-black" to="imprint">
                    {t('menu.item.imprint')}
                  </Link>
                </li>
                <li
                  className="text-black cursor-pointer text-lg list-none"
                  onClick={this.sendMail}
                  onKeyDown={this.sendMail}
                >
                  {t('menu.item.contact-us')}
                </li>
                <li
                  onClick={() => setOpen()}
                  onKeyDown={() => setOpen()}
                  className="cursor-pointer text-lg list-none"
                >
                  <Link className="text-black" to="terms">
                    {t('menu.item.terms')}
                  </Link>
                </li>
              </ul>
            </motion.div>
            <motion.div
              key="reward-all"
              initial={{ x: -150, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.25 }}
              exit={{ x: -150 }}
              className="fixed bottom-0 w-full z-999 mb-4"
            >
              <RewardAll />
            </motion.div>
          </AnimatePresence>
        ) : null}
      </>
    );
  }
}

Menu.propTypes = {
  userLogout: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  auth: PropTypes.instanceOf(Object).isRequired,
  alert: PropTypes.instanceOf(Object).isRequired,
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

Menu.defaultProps = {
  open: false,
};

export const mapStateToProps = (state) => ({
  auth: state.auth,
  alert: state.alert,
});

export default connect(mapStateToProps, {
  userLogout: logout,
})(withTranslation()(withRouter(Menu)));
