import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledBurger = styled.button`
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 60px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 1.4rem 2rem 1rem 1rem;
  z-index: 900;

  &:focus {
    outline: none;
  }

  div {
    width: 20px;
    height: 2px;
    background: var(--color-text);
    transition: all 0.3s linear;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) =>
        open ? 'rotate(45deg)' : 'rotate(0)'};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? '0' : '1')};
      transform: ${({ open }) =>
        open ? 'translateX(5px)' : 'translateX(0)'};
    }

    :nth-child(3) {
      transform: ${({ open }) =>
        open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`;

const Burger = ({ open, setOpen }) => {
  return (
    <StyledBurger open={open} onClick={() => setOpen(!open)}>
      <div />
      <div />
      <div />
    </StyledBurger>
  );
};

Burger.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
};

Burger.defaultProps = {
  open: false,
};

export default Burger;
