import React from 'react';
import PropTypes from 'prop-types';
import ClipLoader from 'react-spinners/ClipLoader';

const styles = (type) => {
  let style = '';
  switch (type) {
    case 'primary':
      style = 'bg-black text-white';
      break;
    case 'secondary':
      style = 'bg-primary text-black ';
      break;
    case 'tertiary':
      style =
        'bg-transparent border border-solid border-color text-color';
      break;
    case 'transparent':
      style = 'bg-transparent text-color';
      break;

    default:
      style = 'bg-white text-primary';
  }
  return `${style} duration-200 ease-in-out outline-none focus:outline-none text-lg py-5 px-8 w-full`;
};

const EVButton = ({
  id,
  children,
  type,
  onClick,
  loading,
  disabled,
  rounded,
}) => (
  <button
    id={id}
    type="button"
    disabled={disabled}
    onClick={onClick}
    className={[
      styles(type),
      disabled ? 'opacity-50' : '',
      rounded && 'rounded-lg',
    ].join(' ')}
  >
    {loading ? (
      <ClipLoader size={30} color="#ffffff" loading />
    ) : (
      children
    )}
  </button>
);

EVButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
  type: PropTypes.string.isRequired,
  id: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  rounded: PropTypes.bool,
  loading: PropTypes.bool,
};

EVButton.defaultProps = {
  onClick: () => {},
  disabled: false,
  rounded: true,
  loading: false,
  id: '',
};

export default EVButton;
