import { SET_NAVBAR } from '../action-types/layout.types';

export const setNavbarVisibility = ({ visible }) => (dispatch) => {
  dispatch({
    type: SET_NAVBAR,
    payload: { navbarVisible: visible },
  });
};

export const setNavbarType = ({ expanded }) => (dispatch) => {
  dispatch({
    type: SET_NAVBAR,
    payload: { navbarExpanded: expanded },
  });
};
