import React from 'react';
import PropTypes from 'prop-types';

const RoundButton = ({
  children,
  isActive,
  onClick,
  disable,
  emoji,
}) => {
  return (
    <button
      type="button"
      onClick={() => onClick()}
      className={[
        'h-16 w-16 flex items-center focus:outline-none justify-center rounded-full bg-secondary text-color hover:text-primary border-2 border-transparent mt-5 hover:border-primary mx-2',
        isActive && 'text-primary border-primary',
        emoji ? 'font-noto-emoji text-3xl' : 'text-2xl',
        disable && 'text-muted hover:text-muted hover:border-muted',
      ].join(' ')}
      disabled={disable}
    >
      <span>{children}</span>
    </button>
  );
};

RoundButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  emoji: PropTypes.bool,
  disable: PropTypes.bool,
};
RoundButton.defaultProps = {
  isActive: false,
  disable: false,
  emoji: false,
};

export default RoundButton;
