import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const Input = ({
  type,
  placeholder,
  value,
  onChange,
  className,
  style,
}) => {
  const ref = useRef(null);

  useEffect(() => {
    if (type === 'checkbox') {
      ref.current.checked = value;
    }
  }, [type, value]);

  return (
    <input
      ref={ref}
      type={type}
      value={value}
      className={className}
      style={style}
      placeholder={placeholder}
      onChange={(e) => onChange(e)}
    />
  );
};

Input.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  style: PropTypes.instanceOf(Object),
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

Input.defaultProps = {
  value: '',
  onChange: () => {},
  style: {},
  className:
    'py-4 px-3 my-2 border border-black rounded-lg w-full focus:outline-none bg-primary placeholder-black',
};

export default Input;
