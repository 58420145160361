import { call, put, takeLatest } from 'redux-saga/effects';
import {
  FETCH_ACCOUNT,
  SET_ACCOUNT_DETAILS,
} from '../action-types/account.types';
import Cosmos from '../../lib/cosmos';
// worker Saga: will be fired on USER_FETCH_REQUESTED actions
export function* fetchAccount({ payload }) {
  try {
    const data = yield call(
      [Cosmos, Cosmos.getAccounts],
      payload.address,
    );
    const coins = data.result.value.coins.filter((coin) => {
      return coin.denom === process.env.REACT_APP_COSMOS_DENOM;
    });
    yield put({
      type: SET_ACCOUNT_DETAILS,
      payload: {
        balance: coins.length ? coins[0].amount : 0,
        ...data.result.value,
        address: payload.address,
      },
    });
  } catch (e) {
    console.log(e);
    yield put({
      type: SET_ACCOUNT_DETAILS,
      payload: { balance: 0 },
    });
  }
}

/*
    Starts fetchUser on each dispatched `USER_FETCH_REQUESTED` action.
    Allows concurrent fetches of user.
  */
export default function* watchAccount() {
  yield takeLatest(FETCH_ACCOUNT, fetchAccount);
}
