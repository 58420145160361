import { combineReducers } from 'redux';

import schedulesReducer from './schedule.reducer';
import layoutReducer from './layout.reducer';
import authReducer from './auth.reducer';
import accountReducer from './account.reducer';
import transactionReducer from './transaction.reducer';
import alertReducer from './alert.reducer';
import themeReducer from './theme.reducer';
import routerReducer from './router.reducer';
import topupReducer from './topup.reducer';
import organizerReducer from './organizer.reducer';

const rootReducer = combineReducers({
  schedule: schedulesReducer,
  layout: layoutReducer,
  auth: authReducer,
  account: accountReducer,
  transactions: transactionReducer,
  alert: alertReducer,
  theme: themeReducer,
  router: routerReducer,
  topups: topupReducer,
  organizer: organizerReducer,
});

export default rootReducer;
