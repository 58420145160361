/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect, useState } from 'react';
import PageLoader from './components/PageLoader';
import { Viewport } from './helpers/Viewport';
import CheckTheme from './helpers/checkTheme';
import withClearCache from './helpers/ClearCache';
import Install from './pages/Install';
import Routes from './routes';

const App = () => {
  const [isStandaloneMode, setIsStandaloneMode] = useState(false);
  const isIOS = () => {
    return (
      [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') &&
        'ontouchend' in document)
    );
  };

  useEffect(() => {
    setIsStandaloneMode(
      window.matchMedia('(display-mode: standalone)').matches,
    );
  }, []);

  const showInstallBanner = () => {
    return !isStandaloneMode && isIOS();
  };

  return (
    <React.Suspense
      fallback={
        <div className="w-full h-screen flex items-center">
          <PageLoader />
        </div>
      }
    >
      <Viewport>
        <CheckTheme />
        {!showInstallBanner() ? (
          <Routes />
        ) : (
          <Install iOS={isIOS()} />
        )}
      </Viewport>
    </React.Suspense>
  );
};

export default withClearCache(App);
