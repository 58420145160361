/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Truncate from 'lodash.truncate';
import Dialog from './Dialog';
import DonateEntry from './partials/donate/Entry';
import DonateComplete from './partials/donate/Complete';
import Schedule from '../../models/schedule';
import { queueTransaction as queueTransactionAction } from '../../redux/actions/transaction.actions';
import withAuth from '../../helpers/withAuth';
import Organizer from '../../models/organizer';

class DonateDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      completed: false,
      loading: false,
      drops: 0,
      reaction: '',
    };
  }

  donate(option, reaction) {
    const { schedule, organizer, account } = this.props;
    const drops = option === 'All' ? account.balance : option;

    if (schedule) {
      const accounts = schedule.beneficiaries.map((beneficiary) => {
        return beneficiary.accountAddress;
      });
      return this.processSingleTransaction(
        drops,
        reaction,
        accounts[0],
        {
          id: schedule.beneficiaries[0].id,
          name: Truncate(
            schedule.beneficiaries[0].account_name ||
              schedule.beneficiaries[0].collective ||
              schedule.beneficiaries[0].name,
            {
              length: 70,
            },
          ),
        },
      );
    }
    if (organizer) {
      return this.processSingleTransaction(
        drops,
        reaction,
        organizer.accountAddress,
        {
          id: organizer.id,
          name: 'Everyone',
        },
      );
    }
    return true;
  }

  processSingleTransaction(drops, reaction, address, receiver) {
    const { auth, queueTransaction } = this.props;
    this.setState({ loading: true });
    queueTransaction({
      drops,
      address,
      details: {
        receiver,
        sender: {
          id: auth.user.uid,
          displayName: auth.user.displayName,
        },
        reaction,
      },
    });
    this.setState({
      completed: true,
      drops,
      loading: false,
      reaction,
    });
  }

  render() {
    const {
      onClose,
      schedule,
      schedules,
      account,
      organizer,
    } = this.props;
    const { completed, drops, loading, reaction } = this.state;

    return (
      <Dialog>
        <>
          {!completed ? (
            <DonateEntry
              onClose={onClose}
              schedule={schedule}
              schedules={schedules}
              organizer={organizer}
              loading={loading}
              onComplete={(e, emoji) => this.donate(e, emoji)}
              // eslint-disable-next-line radix
              balance={parseInt(account.balance)}
            />
          ) : (
            <DonateComplete
              drops={drops}
              reaction={reaction}
              onClose={onClose}
              schedule={schedule}
              schedules={schedules}
              organizer={organizer}
            />
          )}
        </>
      </Dialog>
    );
  }
}

DonateDialog.propTypes = {
  queueTransaction: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  schedule: PropTypes.instanceOf(Schedule),
  schedules: PropTypes.instanceOf(Array),
  organizer: PropTypes.instanceOf(Organizer),
  account: PropTypes.instanceOf(Object).isRequired,
  auth: PropTypes.instanceOf(Object).isRequired,
};

DonateDialog.defaultProps = {
  schedule: null,
  schedules: null,
  organizer: null,
};
export const mapStateToProps = (state) => ({
  account: state.account,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  queueTransaction: queueTransactionAction,
})(withAuth(DonateDialog));
