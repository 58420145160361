import { SET_REDIRECT } from '../action-types/router.types';
import { router as initialState } from '../initialState.json';

const routerReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_REDIRECT:
      return { ...state, redirect: payload };
    default:
      return state;
  }
};

export default routerReducer;
