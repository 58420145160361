import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
// import Moment from 'moment';
import DropsSymbol from '../assets/icons/dropsSymbol';
import SmallLogo from '../assets/icons/smallLogo';
import BigLogo from '../assets/icons/bigLogo';
import pattern from '../assets/icons/pattern.png';
import Menu from './menu';
import PendingTransaction from './PendingTransation';

class Navbar extends React.Component {
  toggle = () => {
    const { showMenu } = this.state;
    this.setState({ showMenu: !showMenu });
  };

  renderBalance() {
    const { auth, account, t } = this.props;
    if (auth?.user && account?.balance >= 0) {
      return (
        <Link
          to="/transactions"
          className="text-color flex items-baseline"
        >
          <span className="text-color">{t('navbar.balance')}</span>
          <span className="tracking-tight flex items-baseline mx-1 text-color">
            <span className="font-bold text-lg">
              {account.balance}
            </span>
            <DropsSymbol color="color" />
          </span>
        </Link>
      );
    }
    return (
      <span className="text-color">
        &nbsp;{' '}
        {t('balance.placeholder') ? t('balance.placeholder') : null}
      </span>
    );
  }

  render() {
    const { layout, theme } = this.props;
    return (
      <>
        <div className="relative z-99">
          <Menu />
          <div className="bg-secondary fixed border-b border-white border-opacity-25 h-16 w-full z-20">
            <div className="relative py-3 px-3">
              <nav className="z-99 bg-secondary flex pt-2 items-center">
                <div className="mt-1 ml-8">
                  <Link to="/" className="inline-block">
                    <SmallLogo />
                  </Link>
                  <span className="font-noto-emoji inline-block invisible">
                    &#10084;
                  </span>
                </div>
                <div className="ml-auto pr-1">
                  <span className="z-1">{this.renderBalance()}</span>
                </div>
              </nav>
            </div>
            <PendingTransaction />
          </div>

          {layout.navbarExpanded ? (
            <div className="relative bg-secondary z-10 inline-block w-full overflow-hidden py-16 pb-5 px-5 top-0 border-b border-white border-opacity-25">
              <Link to="/">
                <BigLogo />
              </Link>
              {theme === 'halloween' && (
                <div
                  className="absolute top-0 left-0 w-full h-64"
                  style={{
                    background: ` linear-gradient(180deg, rgba(255, 255, 255, 0),rgba(255, 255, 255, 0), rgba(110, 88, 146, 0.8)), url(${pattern})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                  }}
                />
              )}
              <PendingTransaction />
            </div>
          ) : (
            <div className="pb-8 pt-12" />
          )}
        </div>
      </>
    );
  }
}

Navbar.propTypes = {
  account: PropTypes.instanceOf(Object),
  auth: PropTypes.instanceOf(Object),
  layout: PropTypes.instanceOf(Object).isRequired,
  t: PropTypes.func.isRequired,
  theme: PropTypes.string,
};

Navbar.defaultProps = {
  account: {},
  auth: {},
  theme: null,
};

export const mapStateToProps = (state) => ({
  account: state.account,
  auth: state.auth,
  layout: state.layout,
  theme: state.theme,
});

export default connect(mapStateToProps)(withTranslation()(Navbar));
