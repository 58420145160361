import { v4 as uuid } from 'uuid';
import BaseModel from './_model';

export default class Organizer extends BaseModel {
  static baseAttributes() {
    return {
      name: '',
      phone: uuid(),
    };
  }

  static COLLECTION() {
    return 'organizers';
  }

  get accountAddress() {
    return this.data.accountAddress;
  }
}
