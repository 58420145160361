import React from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';
import { withTranslation } from 'react-i18next';
import HTMLParser from 'react-html-parser';
import Truncate from 'lodash.truncate';
import DropsIcon from '../../../../assets/icons/dropsIcon';
import Button from '../../../ui/Button';
import Schedule from '../../../../models/schedule';

const DonateComplete = ({
  drops,
  reaction,
  schedule,
  onClose,
  t,
}) => (
  <AnimatePresence>
    <motion.div
      initial={{ x: 100 }}
      animate={{ x: 0 }}
      transition={{ delay: 0.1 }}
      exit={{ opacity: 0 }}
      className="w-full relative bg-black overflow-hidden"
    >
      <div className="py-4 p-6">
        <h1 className="font-primary text-4xl text-primary leading-none">
          {schedule ? (
            schedule.beneficiaries.map((beneficiary) => {
              return (
                <span key={beneficiary.id}>
                  {Truncate(
                    beneficiary.account_name ||
                      beneficiary.collective ||
                      beneficiary.name,
                    {
                      length: 70,
                    },
                  )}
                </span>
              );
            })
          ) : (
            <span>{t('donate.everyone')}</span>
          )}
        </h1>
        <p className="text-primary font-primary text-4xl leading-none">
          {t('donate.popup.complete.title')}
        </p>
        <div className="flex flex-row">
          <span
            className={`text-primary font-normal text-7xl mr-3 ${
              !drops && 'font-noto-emoji'
            }`}
          >
            {drops > 0 ? drops : HTMLParser(reaction)}
          </span>
          {drops > 0 && (
            <span className="mt-8">
              <DropsIcon color="primary" />
            </span>
          )}
        </div>
        <p className="text-base text-primary">
          {HTMLParser(t('donate.popup.complete.body'))}
        </p>
      </div>
      <Button type="secondary" rounded={false} onClick={onClose}>
        {t('action.close')}
      </Button>
    </motion.div>
  </AnimatePresence>
);

DonateComplete.propTypes = {
  onClose: PropTypes.func.isRequired,
  drops: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  schedule: PropTypes.instanceOf(Schedule),
  reaction: PropTypes.string,
  t: PropTypes.func.isRequired,
};

DonateComplete.defaultProps = {
  schedule: null,
  reaction: '',
};

export default withTranslation()(DonateComplete);
