import { SET_THEME } from '../action-types/theme.types';
import { theme as initialState } from '../initialState.json';

const themeReducer = (state = initialState, { type, payload }) => {
  if (type === SET_THEME) return payload;

  return state;
};

export default themeReducer;
