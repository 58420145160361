import { SET_REDIRECT } from '../action-types/router.types';

export const setRedirect = (payload) => (dispatch) => {
  dispatch({ type: SET_REDIRECT, payload });
};

export const redirect = (history, fallback = '/schedule') => (
  dispatch,
  getState,
) => {
  const { router } = getState();
  if (router.redirect && router.redirect.pathname) {
    const { pathname } = router.redirect;
    dispatch(setRedirect(null));
    return history.push(pathname);
  }
  return history.push(fallback);
};
