import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import packageJson from '../../package.json';
import PageLoader from '../components/PageLoader';

const buildDateGreaterThan = (latestDate, currentDate) => {
  const momLatestDateTime = moment(latestDate);
  const momCurrentDateTime = moment(currentDate);

  if (momLatestDateTime.isAfter(momCurrentDateTime)) {
    return true;
  }
  return false;
};

function withClearCache(Component) {
  function ClearCacheComponent(props) {
    const [isLatestBuildDate, setIsLatestBuildDate] = useState(false);

    const refreshCacheAndReload = () => {
      if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then((names) => {
          names.forEach((name) => {
            caches.delete(name);
          });
        });
      }
      // delete browser cache and hard reload
      window.location.reload();
    };

    const checkAppUpdate = useCallback(() => {
      fetch('/meta.json')
        .then((response) => response.json())
        .then((meta) => {
          const latestVersionDate = meta.buildDate;
          const currentVersionDate = packageJson.buildDate;

          const shouldForceRefresh = buildDateGreaterThan(
            latestVersionDate,
            currentVersionDate,
          );
          if (shouldForceRefresh) {
            setIsLatestBuildDate(false);
            refreshCacheAndReload();
          } else {
            setIsLatestBuildDate(true);
          }
        });
    }, []);

    useEffect(() => {
      checkAppUpdate();
      const interval = setInterval(checkAppUpdate, 300000);
      return () => clearInterval(interval);
    }, [checkAppUpdate]);

    return (
      <>
        {isLatestBuildDate ? (
          <Component {...props} />
        ) : (
          <div className="w-full h-screen flex items-center">
            <PageLoader />
          </div>
        )}
      </>
    );
  }

  return ClearCacheComponent;
}

export default withClearCache;
