import { call, put, takeLatest } from 'redux-saga/effects';
import {
  FETCH_SCHEDULES,
  SET_SCHEDULES,
} from '../action-types/schedule.types';
import Schedule from '../../models/schedule';

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* fetchSchedules({ payload }) {
  try {
    const schedules = yield call([Schedule, Schedule.all], {
      address: payload.address,
    });
    yield put({ type: SET_SCHEDULES, payload: schedules });
  } catch (e) {
    console.log(e);
  }
}

/*
    Starts fetchUser on each dispatched `USER_FETCH_REQUESTED` action.
    Allows concurrent fetches of user.
  */
export default function* watchSchedule() {
  yield takeLatest(FETCH_SCHEDULES, fetchSchedules);
}
