import { SET_NAVBAR } from '../action-types/layout.types';
import { layout as initialState } from '../initialState.json';

const layoutReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_NAVBAR:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export default layoutReducer;
