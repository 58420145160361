import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '../../assets/icons/close';

const CloseButton = ({ onClick }) => (
  <button
    type="button"
    className="p-0 b-0 bg-transparent focus:outline-none outline-none"
    onClick={onClick}
  >
    <CloseIcon color="color" />
  </button>
);

CloseButton.propTypes = {
  onClick: PropTypes.func,
};

CloseButton.defaultProps = {
  onClick: () => {},
};

export default CloseButton;
