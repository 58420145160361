import {
  SET_AUTH,
  SET_VERIFICATION_PHONE_NUMBER,
} from '../action-types/auth.types';
import firebase from '../../lib/firebase';
import { setAccount, getBalance } from './account.actions';
import { clearAlert } from './alert.actions';

export const setAuth = (payload) => (dispatch) => {
  dispatch({
    type: SET_AUTH,
    payload,
  });
};

export const reloadUserData = () => (dispatch) => {
  (async () => {
    const user = firebase.auth().currentUser;
    await user.reload();
    const { currentUser } = firebase.auth();
    if (currentUser) {
      return dispatch(
        setAuth({
          loaded: true,
          user: currentUser,
        }),
      );
    }
    return dispatch(
      setAuth({
        loaded: true,
        user: null,
      }),
    );
  })();
};

export const checkAuth = () => (dispatch) => {
  firebase.auth().onAuthStateChanged((authUser) => {
    if (authUser) {
      dispatch(getBalance());
      return dispatch(
        setAuth({
          loaded: true,
          user: authUser,
        }),
      );
    }
    return dispatch(
      setAuth({
        loaded: true,
        user: null,
      }),
    );
  });
};

export const logout = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch(
          setAuth({
            loaded: true,
            user: null,
          }),
        );
        dispatch(setAccount(null));
        dispatch(clearAlert());
        resolve(true);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const setVerificationPhoneNumber = (payload) => (dispatch) => {
  dispatch({
    type: SET_VERIFICATION_PHONE_NUMBER,
    payload: {
      verification: payload,
    },
  });
};
