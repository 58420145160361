import { call, put, takeLatest } from 'redux-saga/effects';
import {
  FETCH_ORGANIZER,
  SET_ORGANIZER,
} from '../action-types/organizer.types';
import Organizer from '../../models/organizer';

function* fetchOrganizer() {
  try {
    const organizer = yield call([Organizer, Organizer.first]);
    yield put({
      type: SET_ORGANIZER,
      payload: organizer ? organizer.data : null,
    });
  } catch (e) {
    console.log(e);
  }
}

export default function* watchOrganizer() {
  yield takeLatest(FETCH_ORGANIZER, fetchOrganizer);
}
