import React from 'react';
import BounceLoader from 'react-spinners/BounceLoader';

const PageLoader = () => {
  return (
    <div className="w-full h-full text-center py-24 flex justify-center items-center text-color">
      <span className="page-loader rounded-full text-primary">
        <BounceLoader className="" size={50} />
      </span>
    </div>
  );
};
export default PageLoader;
