import { SET_ORGANIZER } from '../action-types/organizer.types';
import { organizer as initialState } from '../initialState.json';

const organizerReducer = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case SET_ORGANIZER:
      return payload;
    default:
      return state;
  }
};

export default organizerReducer;
