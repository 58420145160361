import { SET_ACTIVE_TOPUP } from '../action-types/topup.types';
import { theme as initialState } from '../initialState.json';

const topupReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ACTIVE_TOPUP:
      return { ...state, active: payload };
    default:
      return state;
  }
};

export default topupReducer;
