/* eslint-disable react/jsx-indent */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import CloseButton from './ui/CloseButton';
import withoutNavbar from '../helpers/withoutNavbar';

class ContentWrapper extends React.Component {
  render() {
    const { history, children } = this.props;
    return (
      <div className="w-full relative -my-6">
        <div className="mr-0 absolute top-0 right-0 mt-6 z-999">
          <CloseButton onClick={() => history.goBack()} />
        </div>
        <div className="py-2 md-content">{children}</div>
      </div>
    );
  }
}

ContentWrapper.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  children: PropTypes.node,
};

ContentWrapper.defaultProps = {
  children: null,
};

export default withRouter(withoutNavbar(ContentWrapper));
