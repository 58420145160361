import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
  persistStore,
  persistReducer,
  createTransform,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { parse, stringify } from 'flatted';
import initialState from './initialState.json';
import rootReducer from './reducers/rootReducer';
import rootSagas from './sagas';

export const transformCircular = createTransform(
  (inboundState) => stringify(inboundState),
  (outboundState) => parse(outboundState),
);

const persistConfig = {
  key: 'dropsy-storage',
  storage,
  whitelist: [
    'organizer',
    'account',
    'router',
    'auth',
    'topups',
    'schedule',
    'transactions',
  ],
  transforms: [transformCircular], // which reducer want to store
};
const pReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  pReducer,
  initialState,
  composeWithDevTools(applyMiddleware(thunk, sagaMiddleware)),
);
sagaMiddleware.run(rootSagas);
const persistor = persistStore(store);

export { persistor, store };
