import React from 'react';
import PropTypes from 'prop-types';

const DropsIcon = ({ color }) => (
  <svg
    width="56"
    height="91"
    viewBox="0 0 56 91"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.6445 27V18.4688H27.3926C28.1465 18.4688 28.8242 18.6406 29.4258 18.9844C30.0273 19.3242 30.4961 19.8066 30.832 20.4316C31.1719 21.0527 31.3438 21.75 31.3477 22.5234V22.916C31.3477 23.6973 31.1816 24.3984 30.8496 25.0195C30.5215 25.6367 30.0566 26.1211 29.4551 26.4727C28.8574 26.8203 28.1895 26.9961 27.4512 27H24.6445ZM26.7012 20.0566V25.418H27.416C28.0059 25.418 28.459 25.209 28.7754 24.791C29.0918 24.3691 29.25 23.7441 29.25 22.916V22.5469C29.25 21.7227 29.0918 21.1016 28.7754 20.6836C28.459 20.2656 27.998 20.0566 27.3926 20.0566H26.7012Z"
      className={`fill-${color}`}
    />
    <path
      d="M27.8145 35.9883H26.7012V39H24.6445V30.4688H28.002C29.0137 30.4688 29.8047 30.6934 30.375 31.1426C30.9453 31.5918 31.2305 32.2266 31.2305 33.0469C31.2305 33.6406 31.1094 34.1328 30.8672 34.5234C30.6289 34.9141 30.2539 35.2305 29.7422 35.4727L31.5234 38.9121V39H29.3203L27.8145 35.9883ZM26.7012 34.4004H28.002C28.3926 34.4004 28.6855 34.2988 28.8809 34.0957C29.0801 33.8887 29.1797 33.6016 29.1797 33.2344C29.1797 32.8672 29.0801 32.5801 28.8809 32.373C28.6816 32.1621 28.3887 32.0566 28.002 32.0566H26.7012V34.4004Z"
      className={`fill-${color}`}
    />
    <path
      d="M31.8691 46.9102C31.8691 47.7422 31.7148 48.4785 31.4062 49.1191C31.0977 49.7598 30.6582 50.2539 30.0879 50.6016C29.5215 50.9453 28.875 51.1172 28.1484 51.1172C27.4219 51.1172 26.7773 50.9512 26.2148 50.6191C25.6523 50.2832 25.2129 49.8047 24.8965 49.1836C24.584 48.5625 24.4199 47.8496 24.4043 47.0449V46.5645C24.4043 45.7285 24.5566 44.9922 24.8613 44.3555C25.1699 43.7148 25.6094 43.2207 26.1797 42.873C26.7539 42.5254 27.4062 42.3516 28.1367 42.3516C28.8594 42.3516 29.5039 42.5234 30.0703 42.8672C30.6367 43.2109 31.0762 43.7012 31.3887 44.3379C31.7051 44.9707 31.8652 45.6973 31.8691 46.5176V46.9102ZM29.7773 46.5527C29.7773 45.7051 29.6348 45.0625 29.3496 44.625C29.0684 44.1836 28.6641 43.9629 28.1367 43.9629C27.1055 43.9629 26.5605 44.7363 26.502 46.2832L26.4961 46.9102C26.4961 47.7461 26.6348 48.3887 26.9121 48.8379C27.1895 49.2871 27.6016 49.5117 28.1484 49.5117C28.668 49.5117 29.0684 49.291 29.3496 48.8496C29.6309 48.4082 29.7734 47.7734 29.7773 46.9453V46.5527Z"
      className={`fill-${color}`}
    />
    <path
      d="M26.7012 60.1055V63H24.6445V54.4688H28.0488C28.7012 54.4688 29.2773 54.5898 29.7773 54.832C30.2812 55.0703 30.6699 55.4121 30.9434 55.8574C31.2207 56.2988 31.3594 56.8008 31.3594 57.3633C31.3594 58.1953 31.0605 58.8613 30.4629 59.3613C29.8691 59.8574 29.0527 60.1055 28.0137 60.1055H26.7012ZM26.7012 58.5176H28.0488C28.4473 58.5176 28.75 58.418 28.957 58.2188C29.168 58.0195 29.2734 57.7383 29.2734 57.375C29.2734 56.9766 29.166 56.6582 28.9512 56.4199C28.7363 56.1816 28.4434 56.0605 28.0723 56.0566H26.7012V58.5176Z"
      className={`fill-${color}`}
    />
    <path
      d="M29.0449 72.7324C29.0449 72.4316 28.9375 72.1973 28.7227 72.0293C28.5117 71.8613 28.1387 71.6875 27.6035 71.5078C27.0684 71.3281 26.6309 71.1543 26.291 70.9863C25.1855 70.4434 24.6328 69.6973 24.6328 68.748C24.6328 68.2754 24.7695 67.8594 25.043 67.5C25.3203 67.1367 25.7109 66.8555 26.2148 66.6562C26.7188 66.4531 27.2852 66.3516 27.9141 66.3516C28.5273 66.3516 29.0762 66.4609 29.5605 66.6797C30.0488 66.8984 30.4277 67.2109 30.6973 67.6172C30.9668 68.0195 31.1016 68.4805 31.1016 69H29.0508C29.0508 68.6523 28.9434 68.3828 28.7285 68.1914C28.5176 68 28.2305 67.9043 27.8672 67.9043C27.5 67.9043 27.209 67.9863 26.9941 68.1504C26.7832 68.3105 26.6777 68.5156 26.6777 68.7656C26.6777 68.9844 26.7949 69.1836 27.0293 69.3633C27.2637 69.5391 27.6758 69.7227 28.2656 69.9141C28.8555 70.1016 29.3398 70.3047 29.7188 70.5234C30.6406 71.0547 31.1016 71.7871 31.1016 72.7207C31.1016 73.4668 30.8203 74.0527 30.2578 74.4785C29.6953 74.9043 28.9238 75.1172 27.9434 75.1172C27.252 75.1172 26.625 74.9941 26.0625 74.748C25.5039 74.498 25.082 74.1582 24.7969 73.7285C24.5156 73.2949 24.375 72.7969 24.375 72.2344H26.4375C26.4375 72.6914 26.5547 73.0293 26.7891 73.248C27.0273 73.4629 27.4121 73.5703 27.9434 73.5703C28.2832 73.5703 28.5508 73.498 28.7461 73.3535C28.9453 73.2051 29.0449 72.998 29.0449 72.7324Z"
      className={`fill-${color}`}
    />
    <path
      d="M26.5625 0H29.75L56 45.5625L29.75 91H26.5625L0.25 45.5625L26.5625 0ZM28.125 4.0625L4.5625 45.5625L28.125 87.125L51.75 45.5625L28.125 4.0625Z"
      className={`fill-${color}`}
    />
  </svg>
);

DropsIcon.propTypes = {
  color: PropTypes.string,
};

DropsIcon.defaultProps = {
  color: 'color',
};

export default DropsIcon;
