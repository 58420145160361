import { call, put, takeLatest } from 'redux-saga/effects';
import {
  SET_ACTIVE_TOPUP,
  FETCH_TOPUP,
} from '../action-types/topup.types';
import Topup from '../../models/topup';

function* fetchTopup({ payload }) {
  try {
    const topups = yield call([Topup, Topup.all], {
      userId: payload.uid,
    });
    const available = topups.filter((topup) => topup.isActive());
    yield put({
      type: SET_ACTIVE_TOPUP,
      payload: available.length ? available[0].data : null,
    });
  } catch (e) {
    console.log(e);
  }
}

export default function* watchTopup() {
  yield takeLatest(FETCH_TOPUP, fetchTopup);
}
