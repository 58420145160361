import { SHOW_ALERT, CLEAR_ALERT } from '../action-types/alert.types';

export const showAlert = (message, show) => (dispatch) => {
  dispatch({
    type: SHOW_ALERT,
    payload: { message, show },
  });
  // setTimeout(
  //   () => dispatch({ type: HIDE_ALERT, payload: { show: false } }),
  //   timeout,
  // );
};

export const clearAlert = () => (dispatch) => {
  dispatch({
    type: CLEAR_ALERT,
    payload: { message: null, show: false },
  });
  // setTimeout(
  //   () => dispatch({ type: HIDE_ALERT, payload: { show: false } }),
  //   timeout,
  // );
};
