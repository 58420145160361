import {
  SET_AUTH,
  SET_VERIFICATION_PHONE_NUMBER,
} from '../action-types/auth.types';
import { auth as initialState } from '../initialState.json';

const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_AUTH:
      return { ...state, ...payload };
    case SET_VERIFICATION_PHONE_NUMBER:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export default authReducer;
