import moment from 'moment';
import BaseModel from './_model';
import firebase from '../lib/firebase';

export default class Topup extends BaseModel {
  static COLLECTION() {
    return 'topups';
  }

  static all(data) {
    return new Promise((resolve, reject) => {
      const getTopups = firebase
        .functions()
        .httpsCallable('getTopups');
      getTopups(data)
        .then((response) => {
          return resolve(
            response.data.map((el) => {
              return new this(el);
            }),
          );
        })
        .catch((error) => {
          return reject(error);
        });
    });
  }

  get startTime() {
    return moment(this.parseFirestoreDate(this.data.start).toDate());
  }

  get endTime() {
    return moment(this.parseFirestoreDate(this.data.end).toDate());
  }

  timeRemaining() {
    const now = moment();
    return this.endTime.diff(now);
  }

  isActive() {
    if (!this.data) return false;

    const now = moment();
    return (
      this.startTime.isSameOrBefore(now) &&
      this.endTime.isSameOrAfter(now)
    );
  }
}
