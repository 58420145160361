import { all } from 'redux-saga/effects';
import schedule from './schedule.sagas';
import account from './account.sagas';
import organizer from './organizer.sagas';
import topup from './topup.sagas';
import transaction from './transactions.sagas';

export default function* rootSaga() {
  yield all([
    schedule(),
    account(),
    organizer(),
    topup(),
    transaction(),
  ]);
}
