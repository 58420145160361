import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';

class Alert extends Component {
  render() {
    const { message } = this.props;
    if (!message) return null;

    return (
      <div className="text-color w-full min-h-32 py-4 pl-8 pr-5 border border-prime-200 bg-prime-100 rounded-tr rounded-br -ml-8 mt-8 mb-6 z-999 text-left">
        <div className="text-base">{ReactHtmlParser(message)}</div>
      </div>
    );
  }
}

Alert.propTypes = {
  message: PropTypes.string,
};

Alert.defaultProps = {
  message: null,
};

const mapStateToProps = ({ alert }) => ({
  ...alert,
});

export default connect(mapStateToProps, {})(Alert);
