import {
  FETCH_SCHEDULES,
  SET_SCHEDULES,
  SET_LOADING,
} from '../action-types/schedule.types';

export const setSchedules = (payload) => (dispatch) => {
  dispatch({ type: SET_SCHEDULES, payload });
};

export const getSchedules = () => (dispatch, getState) => {
  const { schedule, account } = getState();
  dispatch({
    type: SET_LOADING,
    payload: !schedule?.list,
  });
  dispatch({
    type: FETCH_SCHEDULES,
    payload: {
      address: account?.address ? account?.address : null,
    },
  });
};
