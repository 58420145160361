/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-shadow */
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import firebase from '../lib/firebase';

export default class BaseModel {
  constructor(data) {
    this.data = data;
    // store class scope into a local variable
    const _this = this;

    for (const i in data) {
      ((i) => {
        Object.defineProperty(_this, i, {
          get() {
            return _this.data[i];
          },
          set(value) {
            _this.data[i] = value;
          },
        });
      })(i);
    }
  }

  static baseAttributes() {
    return {};
  }

  static COLLECTION() {
    return '';
  }

  static create(data = this.baseAttributes()) {
    const attributes = {
      ...data,
      created_at: this.timestamp(),
    };
    const id = uuid();
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection(this.COLLECTION())
        .doc(id)
        .set(attributes)
        .then(() => {
          return resolve(
            new this({
              id,
              ...attributes,
              ref: this.ref(id),
            }),
          );
        })
        .catch((error) => {
          return reject(error);
        });
    });
  }

  static first() {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection(this.COLLECTION())
        .limit(1)
        .get()
        .then((querySnapshot) => {
          if (!querySnapshot.empty) {
            resolve(this.structureObject(querySnapshot.docs[0]));
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static find(id) {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection(this.COLLECTION())
        .doc(id)
        .get()
        .then((snapshot) => {
          if (snapshot.exists) {
            return resolve(this.structureObject(snapshot));
          }
          return reject(new Error('Not found'));
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static findByRef(id) {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection(this.COLLECTION())
        .doc(id)
        .get()
        .then((snapshot) => {
          if (snapshot.exists) {
            return resolve();
          }
          return reject(new Error('Not found'));
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static all() {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection(this.COLLECTION())
        .get()
        .then((snapshot) => {
          return resolve(
            snapshot.docs.map((el) => {
              return this.structureObject(el);
            }),
          );
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static ref(id) {
    return firebase.firestore().doc(`${this.COLLECTION()}/${id}`);
  }

  static timestamp() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }

  static structureObject(snapshot) {
    return new this({
      id: snapshot.id,
      ...snapshot.data(),
      ref: snapshot.ref,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  parseFirestoreDate(dateObject) {
    return new firebase.firestore.Timestamp(
      // eslint-disable-next-line no-underscore-dangle
      dateObject._seconds || dateObject.seconds || 0,
      // eslint-disable-next-line no-underscore-dangle
      dateObject._nanoseconds || dateObject.nanoseconds || 0,
    );
  }

  // eslint-disable-next-line class-methods-use-this
  currentDate() {
    // return moment(firebase.firestore.FieldValue.serverTimestamp().toDate());
    return moment();
  }
}
