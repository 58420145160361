import React from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Input from './ui/Input';
import Button from './ui/Button';
import firebase from '../lib/firebase';
import { reloadUserData } from '../redux/actions/auth.actions';
// import CloseButton from './ui/CloseButton';
import withoutNavbar from '../helpers/withoutNavbar';
import BackButton from './ui/BackButton';

class UsernameEntry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      loading: false,
    };
  }

  handleSubmit() {
    const { auth, reloadUser, onSave } = this.props;
    const { username } = this.state;

    if (username.length > 3 && auth.user) {
      this.setState({
        loading: true,
      });
      const updateUser = firebase
        .functions()
        .httpsCallable('updateUserDetails');
      updateUser({
        userId: auth.user.uid,
        username,
      })
        .then(() => {
          reloadUser();
          onSave();
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            loading: false,
          });
        });
    }
  }

  isValid() {
    const { username } = this.state;
    return username.length > 3;
  }

  render() {
    const { t, onClose } = this.props;
    const { username, loading } = this.state;
    return (
      <div className="w-full h-screen relative -my-6 ">
        <div className="left-0 -ml-3 absolute top-0 left-0 mt-3 z-999">
          <BackButton onClick={() => onClose()} />
        </div>
        <div className="pt-5 flex flex-col relative text-color pb-32">
          <div className="w-full relative mt-24">
            <div className="py-2 relative">
              <p className="text-4xl font-primary text-center">
                {t('popup.username.title')}
              </p>
              <p className="text-lg text-center">
                {t('popup.username.subtitle')}
              </p>
            </div>
            <div className="mt-4 w-full">
              <Input
                placeholder={t('join.placeholder.username')}
                type="email"
                value={username}
                onChange={(e) =>
                  this.setState({ username: e.target.value })
                }
              />
            </div>
          </div>
        </div>
        <div className="w-full text-lg fixed left-0 bottom-0 px-6 mb-3">
          <Button
            id="sign-in-button"
            type="primary"
            disabled={!this.isValid()}
            loading={loading}
            onClick={() => this.handleSubmit()}
          >
            {t('navigation.continue')}
          </Button>
        </div>
      </div>
    );
  }
}

UsernameEntry.propTypes = {
  auth: PropTypes.instanceOf(Object).isRequired,
  t: PropTypes.func.isRequired,
  reloadUser: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default connect(null, {
  reloadUser: reloadUserData,
})(withTranslation()(withRouter(withoutNavbar(UsernameEntry))));
