import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import ClipLoader from 'react-spinners/ClipLoader';
import ReactHtmlParser from 'react-html-parser';

class PendingTransaction extends React.Component {
  render() {
    const { transactions, t } = this.props;
    if (!transactions.length) return null;

    return (
      <div className="relative z-0 top-0 w-full py-1 px-4 border border-prime-200 bg-prime-100 text-primary text-sm">
        {transactions.length > 1 && (
          <div className="inline-block  pr-2">
            <div className="inline-block w-5 h-5 bg-primary text-white flex justify-center items-center rounded-full leading-none">
              {transactions.length}
            </div>
          </div>
        )}
        {transactions.length > 1 ? (
          t('transaction.pending')
        ) : (
          <span className="inline-block">
            {t('transaction.sending.part-1')}{' '}
            {transactions[0].drops ? (
              <span>{transactions[0].drops}◊</span>
            ) : (
              <span className="font-noto-emoji">
                {ReactHtmlParser(transactions[0].details.reaction)}
              </span>
            )}{' '}
            {t('transaction.sending.part-2')}{' '}
            {transactions[0].details.receiver.name}
          </span>
        )}
        <span className="inline-block float-right loader">
          <ClipLoader size={12} />
        </span>
      </div>
    );
  }
}

PendingTransaction.propTypes = {
  transactions: PropTypes.instanceOf(Array).isRequired,
  t: PropTypes.func.isRequired,
};

export const mapStateToProps = (state) => ({
  transactions: state.transactions.pending,
});

export default connect(mapStateToProps)(
  withTranslation()(PendingTransaction),
);
