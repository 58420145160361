import {
  SET_TRANSACTIONS,
  SET_PENDING_TRANSACTIONS,
  ADD_PENDING_TRANSACTION,
  SET_TRANSACTIONS_LOADING,
} from '../action-types/transaction.types';
import { transactions as initialState } from '../initialState.json';

const transactionReducer = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case SET_TRANSACTIONS:
      return { ...state, all: [...payload] };
    case ADD_PENDING_TRANSACTION:
      return { ...state, pending: [...state.pending, payload] };
    case SET_PENDING_TRANSACTIONS:
      return { ...state, pending: [...payload] };
    case SET_TRANSACTIONS_LOADING:
      return { ...state, loading: payload };
    default:
      return state;
  }
};

export default transactionReducer;
