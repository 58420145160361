import {
  SHOW_ALERT,
  HIDE_ALERT,
  CLEAR_ALERT,
} from '../action-types/alert.types';
import { alert as initialState } from '../initialState.json';

const alertReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SHOW_ALERT:
    case HIDE_ALERT:
      return { ...state, ...payload };
    case CLEAR_ALERT:
      return payload;
    default:
      return state;
  }
};

export default alertReducer;
