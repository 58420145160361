import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { processPending as processPendingAction } from '../redux/actions/transaction.actions';

class TransactionProvider extends React.Component {
  componentDidMount() {
    const { auth, processPending, account } = this.props;
    if (auth?.user && account) {
      processPending();
    }
  }

  // componentDidUpdate(prevProps) {
  //   const { auth } = this.props;
  //   if (prevProps.auth !== auth) {
  //     this.checkStatus();
  //   }
  // }

  // componentWillUnmount() {
  //   const { interval } = this.state;
  //   if (interval) {
  //     clearInterval(interval);
  //   }
  // }

  render() {
    return null;
  }
}

TransactionProvider.propTypes = {
  processPending: PropTypes.func.isRequired,
  auth: PropTypes.instanceOf(Object).isRequired,
  account: PropTypes.instanceOf(Object).isRequired,
};

export const mapStateToProps = (state) => ({
  auth: state.auth,
  account: state.account,
});

export default connect(mapStateToProps, {
  processPending: processPendingAction,
})(TransactionProvider);
