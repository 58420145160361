import moment from 'moment';
import BaseModel from './_model';
import firebase from '../lib/firebase';

export default class Schedule extends BaseModel {
  static baseAttributes() {
    return {
      starting_date: new Date(),
      ending_date: new Date(),
      delay: 0,
      artists: [],
    };
  }

  static all(data = {}) {
    return new Promise((resolve, reject) => {
      const getSchedules = firebase
        .functions()
        .httpsCallable('getSchedules');
      getSchedules(data)
        .then((response) => {
          return resolve(response.data);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  }

  static parseList(data = []) {
    const result = {};
    Object.keys(data).map((dates) => {
      result[dates] = data[dates].map((el) => new this(el));
      return dates;
    });
    return result;
  }

  static COLLECTION() {
    return 'schedules';
  }

  get startDate() {
    return this.startingDate.format('l');
  }

  get startTime() {
    return `${this.startingDate.format('HH:mm')} h`;
  }

  get startingDate() {
    return moment(
      this.parseFirestoreDate(this.data.starting_date).toDate(),
    ).add(this.data.delay, 'minutes');
  }

  isNowPlaying() {
    return (
      this.startingDate.isSameOrBefore(this.currentDate()) &&
      this.endingDate.isSameOrAfter(this.currentDate())
    );
  }

  isActive() {
    return this.startingDate.isSameOrBefore(this.currentDate());
  }

  isTippable() {
    return this.isActive() && this.droppable;
  }

  isDroppable() {
    return this.droppable;
  }

  get endingDate() {
    return moment(
      this.parseFirestoreDate(this.data.ending_date).toDate(),
    );
  }

  get endTime() {
    return `${this.endingDate.format('HH:mm')} h`;
  }
}
