import {
  SET_SCHEDULES,
  SET_LOADING,
} from '../action-types/schedule.types';
import { schedule as initialState } from '../initialState.json';

const scheduleReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SCHEDULES:
      return { ...state, list: payload };
    case SET_LOADING:
      return { ...state, loading: payload };
    default:
      return state;
  }
};

export default scheduleReducer;
