import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

import InstallIOS from '../components/cards/InstallIos';
import InstallAndroid from '../components/cards/InstallAndroid';

const Install = ({ t, iOS }) => {
  return (
    <div className="p-6">
      <div className="w-full">
        <svg
          viewBox="0 0 329 329"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="329" height="329" rx="17" fill="black" />
          <mask
            id="mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="1"
            y="1"
            width="327"
            height="327"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M82.7305 289.889L117.844 328H153.113L107.692 278.818L82.7305 289.889ZM300.587 285.86L204.694 236.269L116.038 275.158L164.85 328H328V184.252L278.428 205.764L300.587 285.86ZM106.185 327.984H1V325.668L74.3726 293.552L106.185 327.984ZM84.7237 174.341L17.6108 231.975L41.8361 258.213L1 276.342V1.01946H144.331L38.4259 150.377L84.7237 174.341ZM194.724 231.372L110.202 268.568L1 316.041V285.327L47.7921 265.045L162.114 214.496L194.724 231.372ZM227.697 184.159L211.374 191.31L232.738 202.327L227.697 184.159ZM287.086 269.163L227.778 54.9161L125.809 147.185L150.357 159.486L214.644 104.729L246.11 218.899L88.5659 138.624L121.032 94.1327L187.289 1H154.709L51.105 147.297L287.086 269.163ZM158.353 163.823L201.642 186.226L225.4 175.805L209.945 119.923L158.353 163.823ZM152.514 209.33L124.956 195.316L78.1097 234.159L83.3651 239.936L152.514 209.33ZM276.139 197.422L328 175.252V144.522L268.517 170.114L276.139 197.422ZM101.623 135.296L117.35 143.132L232.352 39.2629L266.236 161.771L327.996 134.903V1H197.803L101.623 135.296ZM92.7157 178.658L29.9395 232.679L50.1824 254.546L75.1395 243.603L65.6409 233.353L116.742 191.077L92.7157 178.658Z"
              fill="black"
            />
          </mask>
          <g mask="url(#mask0)">
            <rect
              x="1"
              y="1"
              width="327"
              height="327"
              rx="16"
              fill="white"
            />
          </g>
          <path
            d="M31.2131 27.984C30.2531 30.024 28.5971 31.224 26.0771 31.224C22.3571 31.224 20.2211 28.368 20.2211 24.384C20.2211 20.376 22.3571 17.64 26.0531 17.64C28.7411 17.64 30.3491 18.912 31.2851 21.048H33.8771C32.7971 17.472 30.0851 15.504 26.0771 15.504C20.9891 15.504 17.4851 19.128 17.4851 24.408C17.4851 29.712 20.9651 33.336 25.9571 33.336C30.0131 33.336 32.6771 31.272 33.8531 27.984H31.2131ZM39.8738 33H42.3938V18.048H47.0498V15.84H35.2178V18.048H39.8738V33ZM49.7599 33H52.1839V23.904C52.1839 21.504 52.1599 19.56 52.1359 18.144H52.1599C52.4719 19.296 52.7839 20.568 53.3359 22.224L56.9599 33H59.6239L62.6719 23.952C63.4399 21.576 64.2799 19.008 64.5199 18.144H64.5439C64.5439 19.536 64.4719 22.344 64.4719 24.144V33H66.9199V15.84H62.9839L59.9599 25.056L58.3759 29.928H58.3519C57.8959 28.512 57.2719 26.424 56.8879 25.224L53.8399 15.84H49.7599V33Z"
            fill="black"
          />
        </svg>
      </div>

      <div className="text-4xl font-primary py-3">
        <h1>{ReactHtmlParser(t('app.name'))}</h1>
      </div>

      <div className="text-base">
        {ReactHtmlParser(t('how-it-works.body'))}
      </div>

      {iOS ? <InstallIOS /> : <InstallAndroid />}
    </div>
  );
};

Install.propTypes = {
  t: PropTypes.func.isRequired,
  iOS: PropTypes.bool.isRequired,
};

export default withTranslation()(Install);
