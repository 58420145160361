import React from 'react';
import PropTypes from 'prop-types';
import Truncate from 'lodash.truncate';
import { withTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
import Button from '../../../ui/Button';
import RoundButton from '../../../ui/RoundButton';
import CloseButton from '../../../ui/CloseButton';
import Schedule from '../../../../models/schedule';
import PageLoader from '../../../PageLoader';
import Organizer from '../../../../models/organizer';

class DonateEntry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [1, 5, 10, 20, 50, 100],
      allOptions: [5, 10, 20, 50, 100, 'All'],
      emojis: [
        '&#10084;',
        '&#128163;',
        '&#128293;',
        '&#128077;',
        '&#128075;',
        '&#128079;',
      ],
      drops: null,
      reaction: null,
      showEmojis: false,
    };
  }

  getOptions() {
    const { schedule } = this.props;
    const { options, allOptions } = this.state;
    if (schedule) {
      return options;
    }
    return allOptions;
  }

  hasBalance(value) {
    const { schedules, schedule, balance, organizer } = this.props;
    if (schedule || organizer) {
      return balance >= value || value === 'All';
    }
    return balance >= value * schedules;
  }

  isDroppable() {
    const { schedule, balance, organizer } = this.props;
    if (balance && (schedule?.isDroppable() || organizer)) {
      return true;
    }
    return false;
  }

  render() {
    const { onClose, schedule, onComplete, loading, t } = this.props;
    const { drops, emojis, showEmojis, reaction } = this.state;
    return (
      <div className="w-full relative rounded-lg">
        {loading ? (
          <div className="py-8">
            <PageLoader />
          </div>
        ) : (
          <>
            <div className="pt-4 p-6 relative text-color">
              <span className="absolute right-0 top-0 mt-3 mr-3">
                <CloseButton onClick={() => onClose()} />
              </span>

              <p className="text-3xl font-primary mb-0 leading-none">
                {schedule
                  ? t('donate.popup.title')
                  : t('donate.reward')}
              </p>
              {schedule ? (
                schedule.beneficiaries.map((beneficiary) => {
                  return (
                    <p
                      key={beneficiary.id}
                      className="text-3xl font-primary my-px mb-2 leading-none font-semibold"
                    >
                      {Truncate(
                        beneficiary.account_name ||
                          beneficiary.collective ||
                          beneficiary.name,
                        {
                          length: 70,
                        },
                      )}
                    </p>
                  );
                })
              ) : (
                <p className="text-2xl my-px mb-2 leading-none font-semibold">
                  {t('donate.everyone')}
                </p>
              )}
              <p className="text-base">
                {!(showEmojis || !this.isDroppable())
                  ? t('donate.popup.text')
                  : t('donate.popup.reaction.text')}
              </p>
              <div className="flex flex-wrap justify-center mb-5">
                {!showEmojis &&
                  this.isDroppable() &&
                  this.getOptions().map((option) => {
                    return (
                      <RoundButton
                        isActive={drops === option}
                        onClick={() =>
                          this.setState({ drops: option })
                        }
                        key={option}
                        disable={!this.hasBalance(option)}
                      >
                        {option}
                      </RoundButton>
                    );
                  })}
                {(showEmojis || !this.isDroppable()) &&
                  emojis.map((emoji) => {
                    return (
                      <RoundButton
                        isActive={reaction === emoji}
                        emoji
                        onClick={() =>
                          this.setState({ reaction: emoji })
                        }
                        key={emoji}
                      >
                        {ReactHtmlParser(emoji)}
                      </RoundButton>
                    );
                  })}
              </div>
            </div>
            <Button
              onClick={() => {
                if (drops && showEmojis) {
                  return onComplete(drops, reaction);
                }

                if (reaction && !this.isDroppable()) {
                  return onComplete(0, reaction);
                }

                if (drops) {
                  return this.setState({ showEmojis: true });
                }

                return null;
              }}
              type="primary"
              rounded={false}
            >
              {showEmojis || !this.isDroppable()
                ? t(
                    `donate.popup.button.send${
                      !drops ? '.reaction' : ''
                    }`,
                    { drops },
                  )
                : t('navigation.continue')}
            </Button>
          </>
        )}
      </div>
    );
  }
}

DonateEntry.propTypes = {
  onClose: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  schedule: PropTypes.instanceOf(Schedule),
  organizer: PropTypes.instanceOf(Organizer),
  schedules: PropTypes.instanceOf(Array),
  loading: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  balance: PropTypes.number.isRequired,
};

DonateEntry.defaultProps = {
  schedule: null,
  schedules: null,
  organizer: null,
};

export default withTranslation()(DonateEntry);
