import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import ShareIcon from '../../assets/icons/shareiOS';
import SafariIcon from '../../assets/icons/safari';

const InstallIos = ({ t }) => {
  return (
    <div className="bg-primary bottom-0 fixed w-full left-0 text-center p-6">
      <div className="text-3xl font-primary my-6">
        <span>
          {ReactHtmlParser(t('popup.install.banner.title'))}
        </span>
      </div>
      <div className="text-base pb-5 text-center leading-loose">
        <span>{t('popup.install.banner.ios.part-1')}</span>
        <span className="inline-block mx-2">
          <SafariIcon />
        </span>
        <span>{t('popup.install.banner.ios.part-2')}</span>
        <span className="inline-block mx-2">
          <ShareIcon />
        </span>
        <span>{t('popup.install.banner.ios.part-3')}</span>
      </div>
    </div>
  );
};

InstallIos.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(InstallIos);
