import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import DonateDialog from './dialogs/Donate';
import Button from './ui/Button';
import UsernameEntry from './UsernameEntry';
import { getBalance as getBalanceFunction } from '../redux/actions/account.actions';
import Organizer from '../models/organizer';

class RewardAll extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDialog: false,
      usernamePopup: false,
    };
  }

  onDonate() {
    const { auth } = this.props;
    if (!auth.user.displayName)
      return this.setState({
        usernamePopup: true,
        showDialog: false,
      });

    return this.setState({
      showDialog: true,
    });
  }

  show() {
    const { auth, organizer } = this.props;
    return !(!auth.user || !organizer.data);
  }

  render() {
    const { showDialog, usernamePopup } = this.state;

    const { auth, t, getBalance, organizer } = this.props;

    if (!this.show()) return null;

    if (usernamePopup) {
      return (
        <UsernameEntry
          auth={auth}
          onSave={() => {
            this.setState({
              usernamePopup: false,
              showDialog: true,
            });
          }}
          onClose={() => {
            this.setState({
              usernamePopup: false,
            });
          }}
        />
      );
    }

    return (
      <div className="z-99 fixed bottom-0 right-0 px-8 pb-2 w-full">
        {showDialog && (
          <DonateDialog
            organizer={organizer}
            onClose={() => {
              this.setState({ showDialog: false });
              getBalance();
            }}
          />
        )}
        <Button onClick={() => this.onDonate()} type="primary">
          {t('donate.reward.all')}
        </Button>
      </div>
    );
  }
}

RewardAll.propTypes = {
  getBalance: PropTypes.func.isRequired,
  auth: PropTypes.instanceOf(Object).isRequired,
  organizer: PropTypes.instanceOf(Organizer),
  t: PropTypes.func.isRequired,
};

RewardAll.defaultProps = {
  organizer: null,
};

export const mapStateToProps = (state) => ({
  auth: state.auth,
  organizer: new Organizer(state.organizer),
});

export default connect(mapStateToProps, {
  getBalance: getBalanceFunction,
})(withTranslation()(RewardAll));
