import React from 'react';
import PropTypes from 'prop-types';

const Close = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask-close"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="4"
      y="5"
      width="15"
      height="14"
    >
      <path
        d="M18.9068 6.41L17.5037 5L11.9411 10.59L6.37855 5L4.97547 6.41L10.538 12L4.97547 17.59L6.37855 19L11.9411 13.41L17.5037 19L18.9068 17.59L13.3442 12L18.9068 6.41Z"
        className={`fill-${color}`}
      />
    </mask>
    <g mask="url(#mask-close)">
      <rect width="23.8823" height="24" className={`fill-${color}`} />
    </g>
  </svg>
);

Close.propTypes = {
  color: PropTypes.string.isRequired,
};

export default Close;
